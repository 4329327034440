import { Controller } from 'stimulus'

const formatEuroPrice = (amount) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount)
}

export default class extends Controller {
  static targets = [ 'offer', 'arrival', 'accomodation', 'previewPrice', 'cashbackBadge' ]
  connect() {
  }

  async run() {
    const res = await fetch('/bookings/price_calc', {
      body: JSON.stringify({
        booking: {
          course_offer_id: this.offerId,
          accomodation_id: this.accomodationId,
          arrival_id: this.arrivalId
        }
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST'
    })

    const { price } = await res.json()

    this.previewPriceTarget.value = formatEuroPrice(price)
    if(price > 500) {
      this.cashbackBadgeTarget.classList.remove('d-none')
    } else {
      this.cashbackBadgeTarget.classList.add('d-none')
    }
  }

  get offerId() {
    return this.offerTarget.value
  }

  get accomodationId() {
    return this.hasAccomodationTarget ? this.accomodationTarget.value : ''
  }

  get arrivalId() {
    return this.hasArrivalTarget ? this.arrivalTarget.value : ''
  }
}
