import {Controller} from 'stimulus'



// handles form-input modifications for mgmt creating
// new online courses
// -> /app/views/mgmt/courses/_form.html.erb
//
export default class extends Controller {
  static targets = ['hideable', 'online']

  // if course is online - dont show form-fields
  // related to offline aspects on page load
  //
  initialize() {
    if (this.onlineTarget.checked === true) {
      this.hideOfflineElements()
    }
  }

  // whenever checkbox :online is changed this
  // action gets called
  //
  toggleVisibility(event) {
    if (event.target.checked === true) {
      this.hideOfflineElements()
    } else {
      this.showOfflineElements()
    }
  }

  showOfflineElements() {
    this.hideableTargets.map((ele) => {
      ele.classList.remove('d-none')
    })
  }

  hideOfflineElements() {
    this.hideableTargets.map((ele) => {
      ele.classList.add('d-none')
    })
  }
}
