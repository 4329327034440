import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

/* We load all stimulus controllers from ./controllers
   into our stimulus app.

   see: https://stimulusjs.org/handbook/installing#using-webpack
 */
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
