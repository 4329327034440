import { Popover, Tooltip } from 'bootstrap'

export const setupPopover = () => {
  // see https://getbootstrap.com/docs/5.2/components/popovers/#enable-popovers
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
}

export const setupTooltip = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"], .t-tip')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
}
