/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import RailsUJS from '@rails/ujs'
import Turbolinks from 'turbolinks'
import jQuery from 'jquery'
import Bootstrap from 'bootstrap'
import BootstrapDatepicker from 'bootstrap-datepicker'

window.$ = jQuery

import { abFinish } from 'application/ab_test'
import { prepareAddressForm } from 'application/address_form'
import { prepareImageBackground } from 'application/background'
import { prepareBookingForm } from 'application/bookings'
import { initForm } from 'application/course_offer_updates'
import { setupSmoothScrolling } from 'application/courses'
import { prepareFacetedBrowsing } from 'application/faceted_browsing'
import { setupPopover, setupTooltip } from 'application/helper'
import { prepareIfsrcLink } from 'application/iframed_links'
import { prepareLandingTracking } from 'application/lptracker'
import { prepareMarketing } from 'application/marketing'
import { prepareNavInteract, closeNavInteract } from 'application/nav_interact'
import { prepareNavTracking } from 'application/navtracker'

// loads all stimulus controllers
import 'application/stimulus_app'

import 'application/sessionStorage'

import GoogleAnalytics from 'application/google_analytics'
import PiwikAnalytics from 'application/piwik_analytics'

GoogleAnalytics.load()
PiwikAnalytics.load()

window.GoogleAnalytics = GoogleAnalytics
window.PiwikAnalytics = PiwikAnalytics

RailsUJS.start()
Turbolinks.start()

$(document).on('turbolinks:load', () => {
  prepareAddressForm();
  setupPopover();
  setupTooltip();
  prepareImageBackground();
  prepareBookingForm();
  initForm();
  setupSmoothScrolling();
  prepareFacetedBrowsing();
  prepareIfsrcLink();
  prepareLandingTracking();
  prepareMarketing();
  prepareNavInteract();
  prepareNavTracking()
})

$(document).on('turbolinks:before-visit', closeNavInteract)

$(window).on('beforeunload', closeNavInteract)
